import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"

const NotFoundPage = () => (
  <Layout header={true} footer={true}>
    <SEO title="404: Not found" />
    <Layout header={true} footer={true}>
      <SEO title="Home" />
      <section className="section">
        <div className="container">
          <h1>Not found</h1>
          <p>Can't find wha you're looking for? Get in touch.</p>
        </div>
      </section>
    </Layout>
  </Layout>
)

export default NotFoundPage
